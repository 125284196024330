<script setup>

import { inject, computed, nextTick } from 'vue'
import { convertButtonVariant as toSeverity } from 'core-ui/primeVueBridge/convertBvToPrime.js'

defineProps({
  linkClass: {
    type: [Array, Object, String]
  },
  active: {
    type: Boolean,
    default: false
  },
  variant: {
    type: String,
    default: 'text'
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

defineOptions({
  inheritAttrs: false
})

const $emit = defineEmits(['click'])

const getTastyDropdownParent = inject('getTastyDropdown', () => null)
const tastyDropdownParent = computed(() => {
  if (getTastyDropdownParent) {
    return getTastyDropdownParent()
  }
  return null
})

const onClick = (event) => {
  $emit('click', event)
  if (event.cancelBubble) {
    return
  }
  closeDropdown()
}
const closeDropdown = async () => {
  await nextTick()
  if (tastyDropdownParent.value) {
    tastyDropdownParent.value.hide()
  }
}

const dropdownItemSpecificDesignTokens = {
  padding: {
    x: '4px',
    y: '4px'
  }
}

</script>

<template>
  <li role="presentation">
    <p-button v-bind="$attrs"
              text
              class=" t-dropdown-item btn-nav-menu-item"
              :fluid="true"
              :class="[linkClass, { active, disabled }]"
              :severity="toSeverity(variant)"
              :disabled="disabled"
              role="menu-item"
              :dt="dropdownItemSpecificDesignTokens"
              @click="onClick">
      <slot></slot>
    </p-button>
  </li>
</template>

<style scoped lang="scss">
.t-dropdown-item {
  // Hacking in styles to match bionicNavigation.vue list styles.
  // Migration to 'list' design tokens in a global file (and updating the settings drawer) is coming soon.
  width: 100%;
  white-space: nowrap;
  padding: 0.375rem 0.75rem;
  justify-content: start;
}
</style>
