import { setOptions, setVisible, setAsyncResolve } from 'core-ui/composables/useConfirmAlert'
import { setOptions as setPromptOptions, setVisible as setPromptVisible, setAsyncResolve as setPromptAsyncResolve } from 'core-ui/composables/usePrompt'

export const alertConfirmPromptPlugin = {
  install (Vue) {
    /* == CONFIRM ========================================================== */
    Vue.config.globalProperties.$confirm = function (options) {
      let mergedOptions = {}
      const modalOptions = {
        title: 'Are you sure?',
        'ok-title': 'Yes I\'m sure',
        size: 'sm',
        centered: true,
        'auto-focus-button': 'ok',
        'body-class': 'white-space-pre-line'
      }

      if (typeof options === 'string') {
        modalOptions.text = options
        mergedOptions = modalOptions
      } else {
        const defaultOptions = {
          text: options.text,
          dontAskMeAgain: (options.dontAskMeAgain === true) ? options.dontAskMeAgain : false
        }

        mergedOptions = {
          ...defaultOptions,
          ...modalOptions,
          ...options
        }
      }

      return new Promise(resolve => {
        setOptions(mergedOptions)
        setAsyncResolve(resolve)
        setVisible(true)
      })
    }

    // <template v-slot:modal-footer>
    //   {buttons.map(b => {
    //     const { text, value, ...buttonProps } = b
    //     return <t-button {...{ props: buttonProps }} onclick={() => modalOk(value)}>{text}</t-button>
    //   })}
    // </template>

    /* == ALERT ============================================================ */
    Vue.config.globalProperties.$alert = function (options) {
      let modalOptions = {
        isAlert: true,
        'ok-only': true,
        'ok-title': 'OK',
        'cancel-variant': 'ghost-secondary',
        title: 'Heads Up'
      }

      if (typeof options === 'string') {
        modalOptions.text = options
      } else {
        modalOptions = {
          ...modalOptions,
          ...options
        }
      }

      return Vue.config.globalProperties.$confirm(modalOptions)
    }

    /* == PROMPT =========================================================== */
    Vue.config.globalProperties.$prompt = function (options) {
      let modalOptions = {
        title: 'Just One Thing',
        size: 'sm',
        centered: true,
        'body-class': 'white-space-pre-line'
      }

      if (typeof options === 'string') {
        modalOptions.text = options
      } else {
        const defaultOptions = {
          text: options.text,
          description: '',
          placeholder: '',
          defaultValue: '',
          forms: (Array.isArray(options.forms) && options.forms.length > 0) ? options.forms : false
        }

        modalOptions = {
          ...defaultOptions,
          ...modalOptions,
          ...options
        }
      }

      return new Promise(resolve => {
        setPromptOptions(modalOptions)
        setPromptAsyncResolve(resolve)
        setPromptVisible(true)
      })
    }
  }
}

export default alertConfirmPromptPlugin
