const index = {
  borderRadius: '4px',
  fontWeight: '600',
  colorScheme: {
    light: {
      primary: {
        color: '{primary.800}'
      },
      secondary: {
        color: '{surface.800}'
      },
      success: {
        background: '{success.100}',
        color: '{success.800}'
      },
      info: {
        background: '{info.100}',
        color: '{info.800}'
      },
      warn: {
        background: '{warning.100}',
        color: '{warning.800}'
      },
      danger: {
        background: '{danger.100}',
        color: '{danger.800}'
      }
    }
  }
}

// fontSize used to take precedence over p-component
// 0.8rem style added on body/backpack
const pt = {
  root: {
    class: 'text-nowrap',
    style: {
      fontSize: '0.6875rem'
    }
  }
}

export { index as default, pt }

