<script setup>
// TastyButtonLink is a wrapper component around p-button with some specific overrides
// This creates a button that looks and feels the same as the BootstrapVue <b-link> that was used like a button (not an href)
// We created this component to replace b-links used as buttons. It could also be used to replace t-buttons with the link variant.

defineProps({
  linkHover: { // when true, adds text-decoration: underline on hover which is very link-y
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

// BV_COMPAT - In order to look the same as the old bootstrapVue counterpart, remove the hover background.
const designTokenOverride = {
  root: {
    sm: {
      fontSize: '0.75rem'
    }
  },
  colorScheme: {
    light: {
      text: {
        primary: {
          hoverBackground: 'none',
          activeBackground: 'none'
        },
        secondary: {
          hoverBackground: 'none',
          activeBackground: 'none'
        },
        success: {
          hoverBackground: 'none',
          activeBackground: 'none'
        },
        info: {
          hoverBackground: 'none',
          activeBackground: 'none'
        },
        warn: {
          hoverBackground: 'none',
          activeBackground: 'none'
        },
        danger: {
          hoverBackground: 'none',
          activeBackground: 'none'
        },
        plain: {
          hoverBackground: 'none',
          activeBackground: 'none'
        }
      }
    }
  }
}
</script>

<template>
  <p-button text :class="['p-0 t-button-inline', {
                            [`t-button-inline-${$attrs.severity}`]: $attrs.severity,
                            'link-style': linkHover
                          }]"
            :disabled="disabled"
            :dt="designTokenOverride">
    <slot></slot>
  </p-button>
</template>

<style lang="scss" scoped>
// BV_COMPAT Since we're removing the hoverBackground and activeBackground,
// we need to juje up the active states
.t-button-inline {
  &:hover, &.t-button-inline-success:hover {
    color: var(--p-primary-700) !important;
  }

  &.t-button-inline-secondary:hover {
    color: var(--p-gray-700) !important;
  }

  &.t-button-inline-info:hover {
    color: var(--p-sky-700) !important;
  }

  &.t-button-inline-warn:hover {
    color: var(--p-orange-700) !important;
  }

  &.t-button-inline-danger:hover {
    color: var(--p-red-700) !important;
  }

  &:active,
  &.link-style:hover {
    text-decoration: underline !important;
  }

}
</style>
