const index = {
  text: {
    font: {
      size: '0.8rem',
      weight: 'normal'
    }
  },
  content: {
    padding: '0.75rem 1rem'
  },
  colorScheme: {
    light: {
      success: {
        background: '{success.background.color}',
        color: '{success.color}',
        border: {
          color: '{success.border.color}'
        }
      },
      info: {
        background: '{info.background.color}',
        color: '{info.color}',
        border: {
          color: '{info.border.color}'
        }
      },
      warn: {
        background: '{warning.background.color}',
        color: '{warning.color}',
        border: {
          color: '{warning.border.color}'
        }
      },
      error: {
        background: '{danger.background.color}',
        color: '{danger.color}',
        border: {
          color: '{danger.border.color}'
        }
      }
    }
  }
}

export { index as default }
