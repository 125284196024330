// Design tokens to better match bootstrap buttons FOR NOW until we migrate to prime vue.. 
// the pv buttons were looking too different compared to bv buttons so i put this in the global sheet until we can swap out everything
const index = {
  colorScheme: {
    light: {
      secondary: {
        background: '{primary.50}',
        hoverBackground: '{primary.100}',
        activeBackground: '{primary.100}',
        borderColor: '{primary.50}',
        hoverBorderColor: '{primary.100}',
        activeBorderColor: '{primary.100}',
        color: '{surface.700}',
        hoverColor: '{surface.800}',
        activeColor: '{surface.800}'
      }, 
      link: {
        color: '{primary.700}'
      },
      outlined: {
        primaryColor: '{surface.700}'
      },
      text: {
        primary: {
          color: '{surface.700}'
        }
      }
    }
  },

  padding: {
    x: '0.75rem',
    y: '0.375rem'
  },
  border: {
    radius: '0.25rem'
  },
  label: {
    font: {
      weight: '400'
    }
  }
}

export { index as default }
