<script setup>
import {ref, watch, computed, provide, nextTick } from 'vue'
import {convertButtonVariant, convertSize} from 'core-ui/primeVueBridge/convertBvToPrime.js'

const $props = defineProps({
  block: {
    type: Boolean, // dropdown button is d-inline by default. set this to true to display the button as a block
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  lazy: {
    type: Boolean,
    default: false
  },
  menuClass: {
    type: [Array, Object, String]
  },
  noCaret: {
    type: Boolean,
    default: false
  },
  size: {
    type: String
  },
  split: {
    type: Boolean,
    default: false
  },
  splitButtonAttrs: {
    type: Object
  },
  text: {
    type: String
  },
  toggleAttrs: {
    type: Object,
    default: () => ({})
  },
  toggleClass: {
    type: [Array, Object, String]
  },
  toggleTag: {
    type: String,
    default: 'button'
  },
  toggleIcon: {
    type: String
  },
  toggleIconPos: {
    type: String
  },
  toggleBadge: {
    type: String
  },
  toggleBadgeSeverity: {
    type: String,
    default: 'secondary'
  },
  variant: {
    type: String
  },
  severity: {
    type: String
  }
// i envision eventually a buttonAttrs object and popoverAttrs object that can be bound to the individual pieces
})

defineOptions({
  inheritAttrs: false
})

const $emit = defineEmits(['click', 'toggle-click', 'show', 'shown', 'hide', 'hidden'])

// provide some functions for the inner dropdown components
provide('getTastyDropdown', () => ({
  hide: doHide
}))

// data
const visible = ref(false)
watch(visible, () => {
  // show/hide popover
  // control popover via the visible ref
  if (visible.value) {
    showMenu()
  } else {
    hideMenu()
  }
})


// defineExpose()
// show, hide, toggle popover

/* TOGGLE BUTTON STUFF */
// toggle button handlers
const toggleButtonOnMouseDown = ($event) => {
  $event.stopPropagation()
}
const toggleButtonOnClick = ($event) => {
  doToggle()
  if ($props.split) {
    $emit('toggle-click')
  } else {
    $emit('click', $event)
  }
}
const keycodes = {enter: 13, space: 32, down: 40}
const toggleButtonOnKeydownHandler = ($event) => {
  // enter, space, down keycodes
  if ($event?.keyCode) {
    if ([keycodes.enter, keycodes.space, keycodes.down].includes($event.keyCode)) {
      doToggle()
    }
  }
}
const doToggle = () => {
  if ($props.disabled) {
    doHide()
    return
  }
  if (visible.value) {
    doHide()
  } else {
    doShow()
  }
}
const doShow = () => {
  $emit('show')
  visible.value = true
}
const doHide = () => {
  $emit('hide')
  visible.value = false
}
defineExpose({
  show: doShow,
  hide: doHide,
  toggle: doToggle,
  alignDropdown: async () => {
    await nextTick()
    popoverRef.value?.alignOverlay()
  }
})

const toggleRef = ref(null)
const toggleClasses = computed(() => {
  return [
    $props.toggleClass,
    {
      't-dropdown-toggle-split': $props.split,
      't-dropdown-toggle-no-caret': $props.noCaret && !$props.split
    }
  ]
})
const toggleSeverity = computed(() => {
  if ($props.severity) {
    return $props.severity
  } else if (!['outlined', 'text', 'link'].includes($props.variant)) {
    if ($props.variant?.includes('-')) {
      return convertButtonVariant($props.variant.slice($props.variant.indexOf('-') + 1))
    }
    return convertButtonVariant($props.variant)
  }
})
const toggleVariant = computed(() => {
  if (['outlined', 'text', 'link'].includes($props.variant)) {
    return $props.variant
  } else if ($props.variant?.startsWith('outline')) {
    return 'outlined'
  } else if ($props.variant?.startsWith('ghost')) {
    return 'text'
  } else if ($props.variant?.startsWith('link')) {
    return 'link'
  }
})
/* MENU STUFF */
// menu handlers
const showMenu = async ($event) => {
  popoverRef.value.show({currentTarget: toggleRef.value.$el})
  await nextTick()
  $emit('shown')
//  possibly we need an align overlay here
}
const hideMenu = async () => {
  popoverRef.value.hide()
  await nextTick()
  $emit('hidden')
}

const popoverRef = ref(null)
const menuClasses = computed(() => {
  return [
    $props.menuClass,
    {
      show: visible.value
    }
  ]
})

</script>

<template>
  <component :is="split ? 'p-button-group' : 'div'" class="t-dropdown" :class="[{ 'd-inline': !block && !split }]" v-bind="$attrs">
    <p-button v-if="split"
              v-bind="splitButtonAttrs"
              :label="text"
              @click="$emit('click', $event)">
      <template #default v-if="$slots['button-content']">
        <slot name="button-content"></slot>
      </template>
      <template v-if="($slots['button-icon'] || toggleIcon)" #icon>
        <slot name="button-icon">
          <fluency-icon v-if="toggleIcon" :type="toggleIcon"/>
        </slot>
      </template>
    </p-button>
    <p-button ref="toggleRef"
              v-bind="toggleAttrs"
              class="t-dropdown-toggle"
              :class="toggleClasses"
              :as="toggleTag"
              :severity="toggleSeverity"
              :variant="toggleVariant"
              :size="convertSize(size)"
              :disabled="disabled"
              :iconPos="toggleIconPos"
              :badge="toggleBadge"
              :badgeSeverity="toggleBadgeSeverity"
              :label="text"
              @mousedown="toggleButtonOnMouseDown"
              @click="toggleButtonOnClick"
              @keydown="toggleButtonOnKeydownHandler">
      <template #default v-if="split || $slots['button-content']">
        <span v-if="split" class="sr-only">{{ text }}</span>
        <slot v-else-if="$slots['button-content']" name="button-content"></slot>
      </template>
      <template v-if="!split && ($slots['button-icon'] || toggleIcon)" #icon>
        <slot name="button-icon">
          <fluency-icon v-if="toggleIcon" :type="toggleIcon"/>
        </slot>
      </template>
    </p-button>

    <p-popover ref="popoverRef" @show="doShow" @hide="doHide"
               :pt="{ root: {  style: 'min-width: 15rem; max-width: 40rem; max-height: 95vh; overflow-y: auto' }}">
      <ul role="menu"
          tabindex="-1"
          class="list-unstyled mb-0"
          :class="menuClasses">
        <template v-if="!lazy || visible">
          <slot :hide="doHide"></slot>
        </template>
      </ul>
    </p-popover>

  </component>
</template>

<style scoped lang="scss">
.t-dropdown {
  position: relative;

  .t-dropdown-toggle {
    white-space: nowrap;

    &::after { // caret
      // display: inline-block;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3rem solid $bionic-surface-400;
      border-right: 0.3rem solid transparent;
      border-bottom: 0;
      border-left: 0.3rem solid transparent;
      border-radius: 2px;
    }

    &.t-dropdown-toggle-no-caret::after {
      display: none !important;
    }

    .t-dropdown-toggle-split {
      padding-right: 0.5625rem;
      padding-left: 0.5625rem;

      &::after {
        margin-left: 0;
      }
    }

    .p-button-sm + .t-dropdown-toggle-split,
    .p-button-group-sm > .p-button + .t-dropdown-toggle-split { // not sure if we'll need this second rule for button group since p-button-group has no size prop, but maybe we can add the size prop to the tasty-button-group
      padding-right: 0.375rem;
      padding-left: 0.375rem;
    }

    .p-button-lg + .t-dropdown-toggle-split,
    .p-button-group-lg > .p-button + .t-dropdown-toggle-split {
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }
  }
}

</style>
