const index = {
  tab: {
    borderColor: '{content.border.color}',
    hoverBorderColor: '{content.border.color}',
    color: '{surface.400}',
    hoverColor: '{text.hover.color}',
    activeColor: '{surface.600}',
    padding: '.875rem .75rem',
    gap: '0.5rem'
  },
  active: {
    bar: {
      background: '{surface.600}'
    },
    border: {
      color: '{surface.600}'
    }
  },
  tablist: {
    background: 'transparent'
  },
  tabpanel: {
    background: 'transparent',
    color: '{content.color}'
  }
}

export { index as default }
