const index = {
  background: 'var(--surface-100, #eff1f5)',
  color: 'var(--text-color, #404968)',
  borderRadius: '12px',
  padding: {
    y: '0',
    x: '0.5rem'
  },
  // color and background color design tokens are not updating
  // this should only be a temporary solution
  css: ({ dt }) => (`
.p-chip {
  background: var(--surface-100, #eff1f5);
  color: var(--text-color, #404968);
}
  `)
}

const filterStyles = {
  fontWeight: '600',
  fontSize: '0.8125rem',
  padding: '0.3125rem 0.625rem',
  borderRadius: '16px'
}

const labelStyles = {
  fontWeight: '400',
  fontSize: '0.6875rem'
}

const pt = {
  root: ({ attrs }) => {
    const badgeFilter = attrs?.class && attrs?.class.includes('filter-badge')

    return ({
      style: badgeFilter ? filterStyles : labelStyles
    })
  },
  removeIcon: ({ attrs, state }) => {
    const iconSize = attrs?.class && attrs?.class.includes('filter-badge')
      ? {}
      : {
        width: '0.75rem',
        height: '0.75rem',
        marginRight: '0.125rem'
      }

    return ({
      style: {
        ...iconSize,
        color: 'currentColor'
      }
    })
  }
}

export { index as default, pt }
