// This is to get around the p-component 0.8rem style definition
// Will want to clean this up at some point
const fontSizeMap = {
  small: '0.625rem',
  default: '0.75rem',
  large: '0.75rem',
  xlarge: '0.85rem'
}

const index = {
  colorScheme: {
    light: {
      secondary: {
        color: '{surface.700}'
      },
      success: {
        background: '{success.500}'
      },
      info: {
        background: '{info.500}'
      },
      warn: {
        background: '{warning.500}'
      },
      danger: {
        background: '{danger.500}'
      }
    }
  },
  height: '1rem',
  padding: '0 0.25rem',
  minWidth: '1rem',
  borderRadius: '4px',
  sm: {
    height: '0.75rem',
    minWidth: '0.75rem'
  },
  lg: {
    height: '1rem',
    minWidth: '1rem'
  },
  xl: {
    height: '1.5rem',
    minWidth: '1.5rem'
  },
  css: ({ dt }) => (`
.p-badge:not([class*="p-badge-"]) {
  background: ${dt('badge.secondary.background')};
  color: ${dt('badge.secondary.color')};
}
.p-badge .fluency-svg-icon {
  top: -1px;
}
  `)
}

const pt = {
  root: ({ props }) => ({
    style: {
      fontSize: fontSizeMap[props.size] || fontSizeMap.default,
      lineHeight: 'initial'
    }
  })
}

export { index as default, pt }
