import { definePreset, palette, updatePreset } from '@primevue/themes'
import Aura from '@primevue/themes/aura'
import PaginatorTheme from 'core-ui/primeVueBridge/theme/paginator.js'
import MessageTheme from 'core-ui/primeVueBridge/theme/message.js'
import ToastTheme, { pt as ToastPassThrough } from 'core-ui/primeVueBridge/theme/toast.js'
import TabsTheme from 'core-ui/primeVueBridge/theme/tabs.js'
import AccordionTheme from 'core-ui/primeVueBridge/theme/accordion.js'
import ButtonTheme from 'core-ui/primeVueBridge/theme/button.js'
import BadgeTheme, { pt as BadgePassThrough } from 'core-ui/primeVueBridge/theme/badge.js'
import ChipTheme, { pt as ChipPassThrough } from 'core-ui/primeVueBridge/theme/chip.js'
import TagTheme, { pt as TagPassThrough } from 'core-ui/primeVueBridge/theme/tag.js'

const FluencyTheme = definePreset(Aura, {
  primitive: {
    borderRadius: {
      none: '0',
      xs: '2px',
      sm: '4px',
      md: '6px',
      lg: '8px',
      xl: '12px',
    },
    bionicIndigo: {
      25: '#f5f8ff',
      50: '#eef4ff',
      100: '#e0eaff',
      200: '#c7d7fe',
      300: '#a4bcfd',
      400: '#8098f9',
      500: '#6172f3',
      600: '#444ce7',
      700: '#3538cd',
      800: '#2d31a6',
      900: '#2d3282',
      950: '#1f235b'
    }, 
    bionicGrey: {
      0: 'ffffff',
      25: '#fcfcfd',
      50: '#f9f9fb',
      100: '#eff1f5',
      200: '#dcdfea',
      300: '#b9c0d4',
      400: '#7d89b0',
      500: '#5d6b98',
      600: '#4a5578',
      700: '#404968',
      800: '#30374f',
      900: '#111322',
      950: '#0e101b'
    }, 
    bionicRed: {
      25: '#fffbfa',
      50: '#fef3f2',
      100: '#fee4e2',
      200: '#fecdca',
      300: '#fda29b',
      400: '#f97066',
      500: '#f04438',
      600: '#d92d20',
      700: '#b42318',
      800: '#912018',
      900: '#7a271a',
      950: '#55160c'

    }, 
    bionicBlue: {
      25: '#f5fbff',
      50: '#f0f9ff',
      100: '#e0f2fe',
      200: '#b9e6fe',
      300: '#7cd4fd',
      400: '#36bffa',
      500: '#0ba5ec',
      600: '#0086c9',
      700: '#026aa2',
      800: '#065986',
      900: '#0b4a6f',
      950: '#062C41'
    }, 
    bionicYellow: {
      25: '#fffcf5',
      50: '#fffaeb',
      100: '#fef0c7',
      200: '#fedf89',
      300: '#fec84b',
      400: '#fdb022',
      500: '#f79009',
      600: '#dc6803',
      700: '#b54708',
      800: '#93370d',
      900: '#7a2e0e',
      950: '#4e1d09'

    }, 
    bionicGreen: {
      50: '#f0f8ed',
      100: '#e2f0db',
      200: '#c5e1b7',
      300: '#a8d293',
      400: '#8bc36f',
      500: '#6eb44b',
      600: '#58903c',
      700: '#426c2d',
      800: '#2c481e',
      900: '#112213'
    }
  },
  semantic: {
    transitionDuration: '0.2s',
    focusRing: {
      width: '1px',
      style: 'solid',
      color: '{primary.color}',
      offset: '2px',
      shadow: 'none',
    },
    disabledOpacity: '0.6',
    iconSize: '1rem',
    anchorGutter: '2px',
    primary: {
      25: '{bionicIndigo.25}',
      50: '{bionicIndigo.50}',
      100: '{bionicIndigo.100}',
      200: '{bionicIndigo.200}',
      300: '{bionicIndigo.300}',
      400: '{bionicIndigo.400}',
      500: '{bionicIndigo.500}',
      600: '{bionicIndigo.600}',
      700: '{bionicIndigo.700}',
      800: '{bionicIndigo.800}',
      900: '{bionicIndigo.900}',
      950: '{bionicIndigo.950}',  
    },
    warning: {
      50: '{bionicYellow.50}',
      100: '{bionicYellow.100}',
      200: '{bionicYellow.200}',
      300: '{bionicYellow.300}',
      400: '{bionicYellow.400}',
      500: '{bionicYellow.500}',
      600: '{bionicYellow.600}',
      700: '{bionicYellow.700}',
      800: '{bionicYellow.800}',
      900: '{bionicYellow.900}',  
    },
    danger: {
      50: '{bionicRed.50}',
      100: '{bionicRed.100}',
      200: '{bionicRed.200}',
      300: '{bionicRed.300}',
      400: '{bionicRed.400}',
      500: '{bionicRed.500}',
      600: '{bionicRed.600}',
      700: '{bionicRed.700}',
      800: '{bionicRed.800}',
      900: '{bionicRed.900}',  
    },
    info: {
      50: '{bionicBlue.50}',
      100: '{bionicBlue.100}',
      200: '{bionicBlue.200}',
      300: '{bionicBlue.300}',
      400: '{bionicBlue.400}',
      500: '{bionicBlue.500}',
      600: '{bionicBlue.600}',
      700: '{bionicBlue.700}',
      800: '{bionicBlue.800}',
      900: '{bionicBlue.900}',  
    },
    success: {
      50: '{bionicGreen.50}',
      100: '{bionicGreen.100}',
      200: '{bionicGreen.200}',
      300: '{bionicGreen.300}',
      400: '{bionicGreen.400}',
      500: '{bionicGreen.500}',
      600: '{bionicGreen.600}',
      700: '{bionicGreen.700}',
      800: '{bionicGreen.800}',
      900: '{bionicGreen.900}',  
    },
    formField: {
      paddingX: '0.75rem',
      paddingY: '0.5rem',
      sm: {
        fontSize: '0.875rem',
        paddingX: '0.625rem',
        paddingY: '0.375rem',
      },
      lg: {
        fontSize: '1.125rem',
        paddingX: '0.875rem',
        paddingY: '0.625rem',
      },
      borderRadius: '{border.radius.md}',
      focusRing: {
        width: '0',
        style: 'none',
        color: 'transparent',
        offset: '0',
        shadow: 'none',
      },
      transitionDuration: '{transition.duration}',
    },
    list: {
      padding: '0.25rem 0.25rem',
      gap: '2px',
      header: {
        padding: '0.5rem 1rem 0.25rem 1rem',
      },
      option: {
        padding: '0.5rem 0.75rem',
        borderRadius: '{border.radius.md}',
      },
      optionGroup: {
        padding: '0.5rem 0.75rem',
        fontWeight: '600',
      },
    },
    content: {
      borderRadius: '{border.radius.md}',
    },
    mask: {
      transitionDuration: '0.15s',
    },
    navigation: {
      list: {
        padding: '0.25rem 0.25rem',
        gap: '2px',
      },
      item: {
        padding: '0.5rem 0.75rem',
        borderRadius: '{border.radius.sm}',
        gap: '0.5rem',
      },
      submenuLabel: {
        padding: '0.5rem 0.75rem',
        fontWeight: '600',
      },
      submenuIcon: {
        size: '0.875rem',
      },
    },
    overlay: {
      select: {
        borderRadius: '{border.radius.md}',
        shadow:
          '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)',
      },
      popover: {
        borderRadius: '{border.radius.md}',
        padding: '0.75rem',
        shadow:
          '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)',
      },
      modal: {
        borderRadius: '{border.radius.md}',
        padding: '1.25rem',
        shadow:
          '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1)',
      },
      navigation: {
        shadow:
          '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)',
      },
    },
    colorScheme: {
      light: {
        surface: {
          0: '#ffffff',
          25: '{bionicGrey.25}',
          50: '{bionicGrey.50}',
          100: '{bionicGrey.100}',
          200: '{bionicGrey.200}',
          300: '{bionicGrey.300}',
          400: '{bionicGrey.400}',
          500: '{bionicGrey.500}',
          600: '{bionicGrey.600}',
          700: '{bionicGrey.700}',
          800: '{bionicGrey.800}',
          900: '{bionicGrey.900}',
          950: '{bionicGrey.950}',
        },
        success: {
          color: '{success.700}',
          backgroundColor: '{success.100}',
          borderColor: '{success.100}',
          contrastColor: '#ffffff',
          hoverColor: '{success.800}',
          activeColor: '{success.800}',
        },
        info: {
          color: '{info.700}',
          backgroundColor: '{info.100}',
          borderColor: '{info.100}',
          contrastColor: '#ffffff',
          hoverColor: '{info.800}',
          activeColor: '{info.800}',
        },
        warning: {
          color: '{warning.800}',
          backgroundColor: '{warning.100}',
          borderColor: '{warning.100}',
          contrastColor: '#ffffff',
          hoverColor: '{warning.900}',
          activeColor: '{warning.900}',
        },
        danger: {
          color: '{danger.700}',
          backgroundColor: '{danger.100}',
          borderColor: '{danger.100}',
          contrastColor: '#ffffff',
          hoverColor: '{danger.800}',
          activeColor: '{danger.800}',
        },
        primary: {
          color: '{primary.500}',
          contrastColor: '#ffffff',
          hoverColor: '{primary.600}',
          activeColor: '{primary.600}',
        },
        highlight: {
          background: '{primary.50}',
          focusBackground: '{primary.100}',
          color: '{primary.600}',
          focusColor: '{primary.600}',
        },
        mask: {
          background: 'rgba(0,0,0,0.4)',
          color: '{surface.200}',
        },
        formField: {
          background: '{surface.0}',
          disabledBackground: '{surface.25}',
          filledBackground: '{surface.50}',
          filledHoverBackground: '{surface.50}',
          filledFocusBackground: '{surface.50}',
          borderColor: '{surface.200}',
          hoverBorderColor: '{surface.200}',
          focusBorderColor: '{primary.color}',
          invalidBorderColor: '{bionicRed.400}',
          color: '{surface.700}',
          disabledColor: '{surface.300}',
          placeholderColor: '{surface.300}',
          invalidPlaceholderColor: '{bionicRed.400}',
          iconColor: '{surface.500}',
          shadow: '0 1px 4px rgba(48, 55, 79, 0.15)',
        },
        // text: {
        //   color: '{surface.700}',
        //   hoverColor: '{surface.800}',
        //   mutedColor: '{surface.500}',
        //   hoverMutedColor: '{surface.600}',
        // },
        content: {
          // background: '{surface.0}',
          hoverBackground: '{primary.50}',
          borderColor: '{surface.100}',
          // color: '{text.color}',
          // hoverColor: '{text.hover.color}',
        },
        overlay: {
          select: {
            background: '{surface.0}',
            borderColor: '{surface.200}',
            color: '{text.color}',
          },
          popover: {
            background: '{surface.0}',
            borderColor: '{surface.200}',
            color: '{text.color}',
          },
          modal: {
            background: '{surface.0}',
            borderColor: '{surface.200}',
            color: '{text.color}',
          },
        },
        list: {
          option: {
            focusBackground: '{surface.100}',
            selectedBackground: '{highlight.background}',
            selectedFocusBackground: '{highlight.focus.background}',
            color: '{text.color}',
            focusColor: '{text.hover.color}',
            selectedColor: '{highlight.color}',
            selectedFocusColor: '{highlight.focus.color}',
            icon: {
              color: '{surface.400}',
              focusColor: '{surface.500}',
            },
          },
          optionGroup: {
            background: 'transparent',
            color: '{text.muted.color}',
          },
        },
        navigation: {
          item: {
            focusBackground: '{surface.100}',
            activeBackground: '{surface.100}',
            color: '{text.color}',
            focusColor: '{text.hover.color}',
            activeColor: '{text.hover.color}',
            icon: {
              color: '{surface.400}',
              focusColor: '{surface.500}',
              activeColor: '{surface.500}',
            },
          },
          submenuLabel: {
            background: 'transparent',
            color: '{text.muted.color}',
          },
          submenuIcon: {
            color: '{surface.400}',
            focusColor: '{surface.500}',
            activeColor: '{surface.500}',
          },
        },
      },
    },
  },

  components: {
    paginator: PaginatorTheme,
    message: MessageTheme,
    toast: ToastTheme,
    tabs: TabsTheme,
    accordion: AccordionTheme,
    button: ButtonTheme,
    badge: BadgeTheme,
    chip: ChipTheme,
    tag: TagTheme
  }
})

const FluencyPassThrough = {
  toast: ToastPassThrough,
  badge: BadgePassThrough,
  chip: ChipPassThrough,
  tag: TagPassThrough,
  popover: {
    root: { style: 'max-height: 90vh; overflow-y: auto; max-width: 50vw;' }
  }
}

export { FluencyTheme, FluencyPassThrough }

// when these themes are updated, use the updatePreset call in HMR so that we don't get a full page reload
if (import.meta.hot) {
  import.meta.hot.accept((newTheme) => {
    updatePreset(newTheme.default)
  })
}
