const index = {
  'text.gap': '0.25rem',
  summary: {
    font: { 
      size: '0.9rem'
    }
  },
  detail: {
    font: {
      weight: 450,
      size: '0.8rem'
    }
  },
  colorScheme: {
    light: {
      success: {
        background: '{success.background.color}',
        detail: {
          color: '{success.color}'
        },
        border: {
          color: '{success.border.color}'
        },
        'close.button.focus.ring.color': '{success.color}'
      },
      info: {
        background: '{info.background.color}',
        detail: {
          color: '{info.color}'
        },
        border: {
          color: '{info.border.color}'
        },
        'close.button.focus.ring.color': '{info.color}'
      },
      warn: {
        background: '{warning.background.color}',
        detail: {
          color: '{warning.color}'
        },
        border: {
          color: '{warning.border.color}'
        },
        'close.button.focus.ring.color': '{warning.color}'
      },
      error: {
        background: '{danger.background.color}',
        detail: {
          color: '{danger.color}'
        },
        border: {
          color: '{danger.border.color}'
        },
        'close.button.focus.ring.color': '{danger.color}'
      }
    }
  }
}

const pt = {
  root: {
    style: {
      zIndex: '11200' 
    }
  }
}

export { index as default, pt }
