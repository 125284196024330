export const Colab = (flujax) => ({
  async getSpecs ({ questionnaireSpecId, label, status }) {
    return await flujax.fetch.post('/questionnaire/spec/get', { questionnaireSpecId, label, status })
  },
  async getSpecDetails (questionnaireSpecId, version) {
    let route = `/questionnaire/spec/${questionnaireSpecId}/get`
    if (version) {
      route = route + `?version=${version}`
    }
    return await flujax.fetch.post(route)
  },
  async createDraft (questionnaireSpecDto) {
    if (!questionnaireSpecDto) {
      return
    }
    return await flujax.set.post('/questionnaire/spec/createDraft', questionnaireSpecDto)
  },
  async saveSpec (questionnaireSpecDto) {
    return await flujax.set.post('/questionnaire/spec/save', questionnaireSpecDto)
  },
  async generateQuestionnaireKey ({ user, accountPlanId, label, sendEmail, version, previewSpecData }) {
    if (!user || !accountPlanId) {
      return
    }
    const params = [`user=${encodeURIComponent(user)}`, `accountPlanId=${accountPlanId}`, `sendEmail=${sendEmail || true}`]
    if (label) {
      params.push(`questionnaireSpecLabel=${label}`)
    }
    if (version) {
      params.push(`questionnaireSpecVersion=${version}`)
    }

    return flujax.post(`/generated/key?${params.join('&')}`, previewSpecData || {})
  },
  async getBuilderData ({ accountPlanId }) {
    if (!accountPlanId) {
      return
    }
    return await flujax.fetch.post(`/questionnaire/builderData?accountPlanId=${accountPlanId}`)
  },
  async approveForm (submission) {
    return await flujax.post('/listData/colab/data/approve', submission)
  },
  async disapproveForm (submission) {
    return await flujax.post('/listData/colab/data/cancel', submission)
  }
})
